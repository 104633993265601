import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import "./App.css";
import Home from "./Home";
import Admin from "./Admin";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "./api";

const Logo = styled.img`
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 42px;
  font-weight: bold;
  color: #1b4788;
  line-height: 44px;
  margin-bottom: 8px;
`;

const PngIcon = styled.img`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 40px;
  height: 40px;
  background-color:#fff;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px -4px #000000;
  &.right {
    left: auto;
    right: 24px;
  }
`;

const languages = ["hr", "en"];
const getNextLangIdx = (cIdx) => {
  cIdx++;
  if (cIdx >= languages.length) cIdx = 0;
  return cIdx;
};

function App() {
  const { i18n, t } = useTranslation();
  const [cLang, setCLang] = useState(languages.indexOf(i18n.language));
  const [toggle, doToggle] = useState(0);
  const [messages,setMessages] = useState([]);

  useEffect(() => {
    const getData = async()=>{
      const res  = await api.getMessages();
      setMessages(res.data);
    }
    getData();
    
    window.onclick = function (event) {
      var modal = document.getElementById("infoModal");
      if (event.target === modal) {
        modal.style.display = "none";
        modal.style.opacity = "0";
      }
    };

  }, []);

  const handleLangChange = () => {
    const nLangIdx = getNextLangIdx(cLang);
    setCLang(nLangIdx);
    i18n.changeLanguage(languages[nLangIdx]);
  };

  if(!messages.length) return null;

  return (
    <div className="App">
      <Logo src="/images/logo.png" alt="logo" />

      <PngIcon
        className="right"
        src={`/images/${languages[getNextLangIdx(cLang)]}.png`}
        onClick={(ev) => handleLangChange(ev)}
      />
      <PngIcon
        src={`/images/info.png`}
        onClick={() => doToggle((prev) => prev + 1)}
      />
      <Title>{t("spilja")}</Title>
      
      <Toaster
        toastOptions={{
          style: {
            border: "1px solid #1b4788",
            padding: "16px",
            color: "#1b4788",
          },
        }}
      />
      <div className="Content">
        <BrowserRouter>
          <Routes>
            <Route index element={<Home showModal={toggle} />} />
            <Route path="admin" element={<Admin />}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
