import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import default_en from "./en.json";
import default_hr from "./hr.json";

import { v4 as uuidv4 } from "uuid";

export const defaultNS = "default";
export const resources = {
  en: {
    default: default_en,
  },
  hr: {
    default: default_hr,
  },
};

const supportedLanguages = ["en", "hr"];

let lang =
  localStorage.getItem("spilja_lang") ||
  navigator.language.split("-")[0].toLowerCase();
if (!supportedLanguages.includes(lang)) lang = "hr";

i18n.on("languageChanged", (lng) => {
  localStorage.setItem("spilja_lang", lng);
});

i18n.use(initReactI18next).init({
  lng: lang,
  fallbackLng: "hr",
  // ns: ["default", "forms"],
  defaultNS,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

let _uuid = localStorage.getItem("spilja_uuid");
if (!_uuid) {
  _uuid = uuidv4();
  localStorage.setItem("spilja_uuid", _uuid);
}
window._uuid_ = _uuid;
