import styled from "styled-components";
import { useState, useEffect, Fragment } from "react";
import Spinner from "react-spinkit";
import api, {messages} from "./api";
import { ReactComponent as ReloadIcon } from "./assets/refresh.svg";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const ScreenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  max-width: 768px;
  margin: 0 auto;
  background-color: transparent;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;

  &.bottom {
    margin: 0;
  }
`;

const Card = styled.section`
  position: relative;
  width: 100%;
  height: 46%;
  min-height: 110px;
  color: #fff;
  background-color: #1b4788;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 54px;
  font-weight: bold;
  margin: 10px 0;
  max-height: 200px;
  border-radius: 16px;

  &.error {
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    padding: 24px 16px;
    & p {
      margin: 20px 0px;
    }
  }

  &.offline { 
    flex-direction: column;
    min-height: 0px;
    max-height: 50px;
    margin-left: -24px;
    margin-right: -24px;
    background: #c50101;
    color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    padding:0;
    width: calc(100% + 48px);
  }

  &.online {
    flex-direction: column;
    min-height: 0px;
    max-height: 50px;
    margin-left: -24px;
    margin-right: -24px;
    background: #fff;
    color: #1b4788;
    border-top: 1px solid #1b4788;
    border-bottom: 1px solid #1b4788;
    border-radius: 0;
    padding:0;
    width: calc(100% + 48px);
  }
`;

const CardTitle = styled.div`
  position: absolute;
  left: 0;
  top: 12px;
  width: 100%;
  text-align: center;
  font-size: 16px;
`;

const Loading = styled.div`
  position: absolute;
  background-color: transparent;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReloadButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto;
  background-color: #fff;
  color: #1b4788;
  padding: 0px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px -4px #000000;

  & svg {
    width: 64px;
    height: 64px;
  }
`;

const ReFont = styled.div`
  margin-top: 16px;
  font-size: 10vh;
`;

const InfoLine = styled.div`
  width: 100%;
  height: 3px;
  display: flex;
  justify-content: center;
  margin: 25px 0px 20px;
  border-bottom-width: 3px;
  border-bottom: solid var(--stateColor);
  &.tight {
    margin: 0;
  }
  &.dashed {
    border-bottom: dashed var(--stateColor);
    border-width: 6px;
  }
  &.solid {
    border-bottom: solid var(--stateColor);
    border-width: 6px;
  }
`;

const Info = styled.div`
  margin: 16px 0px;
  font-size: 18px;
  text-align: left;
  padding: 0px;
  border-radius: 8px;
`;

const InfoTitle = styled.span`
  float: left;
  margin: 20px 0px 5px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;

  &.left {
    text-align: center;
    line-height: 28px;
    animation: pulse 2s linear infinite;
    transform-origin: center;
    max-width: fit-content;
    margin: 16px auto 0px;
  }
`;

function Home({ showModal }) {
  const { t } = useTranslation();
  const [msState, setMsState] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const [lastStatus, setLastStatus] = useState(
    localStorage.getItem("spilja_status") || 0
  );
  const [online, setOnline] = useState(true);
  const [msgIndex, setMsgIndex] = useState(0);
  const [message, setMessage] = useState({});

  const getMsData = async () => {
    const res = await api.getData();
    const status = await api.checkStatus();
    let staIdx = 0;
    try {
      staIdx = Number(res.data.status);
    } catch (ex) {}

    const msg = messages.filter(m=>m.id===String(staIdx))[0];
    setMessage(msg);
    document.documentElement.style.setProperty(
      "--stateColor",
      msg.color
    );
    setMsgIndex(staIdx);
    setOnline(status);
    setMsState(res);
    if (staIdx===-1 || staIdx !== Number(lastStatus) || Number(res.data.tMax) === 0) {
      toggleModal(true);
    }
    localStorage.setItem("spilja_status", staIdx);
    setLastStatus(staIdx);
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (showModal > 0 && !msState.loading && !msState.error) toggleModal(true);
  }, [showModal]);

  const refresh = async () => {
    setMsState({
      loading: true,
      error: false,
      data: null,
    });
    setTimeout(() => {
      getMsData();
    }, 3000);
  };

  const toggleModal = (show) => {
    var modal = document.getElementById("infoModal");
    modal.style.display = show ? "flex" : "none";
    modal.style.opacity = show ? "1" : "0";
    const content = document.querySelector(".modal-content");
    content.scrollTop = 0;
  };

  const soldTickets = !msState.data
    ? 0
    : msState.data.tMax === "0"
    ? 0
    : Math.max(Number(msState.data.tMax), Number(msState.data.tFrom));
  const currentTicket = !msState.data ? 0 : Number(msState.data?.tFrom);

  const composeMessage = () => {
    let result = message["text_" + i18next.language];
    result += "<img src='/images/arhipelag.jpg' />" + t("msPoruka");
    return result;
  };

  return (
    <ScreenWrapper>
      <div id="infoModal" className="modal">
        <div className="modal-content">
          <div className="sticky">
            <span className="close" onClick={() => toggleModal(false)}>
              &times;
            </span>
            <InfoTitle style={{ textAlign: "center", width: "100%" }}>
              {t("info")}
            </InfoTitle>
            <InfoLine className={`${message.type}`} />
          </div>
          <Info
            dangerouslySetInnerHTML={{
              __html: composeMessage(),
            }}
          />
        </div>
      </div>

      {msState.loading && (
        <>
          <Loading>
            <Spinner
              fadeIn="none"
              name="cube-grid"
              style={{ width: 100, height: 100 }}
              color="#1b4788"
            />
          </Loading>
        </>
      )}
      {msState.error && (
        <>
        <ReloadButton onClick={() => refresh()}>
            <ReloadIcon />
          </ReloadButton>

          <Card className="error">
            <p>GREŠKA</p>
            <p>
              Zbog tehničkih poteškoća trenutno ne možemo prikazati stanje
              redomata
            </p>
            <p>Molimo pokušajte kasnije</p>
          </Card>
        </>
      )}
      {!msState.loading && !msState.error && (
        <>
          <ReloadButton onClick={() => refresh()}>
            <ReloadIcon />
          </ReloadButton>

          <Card>
            <CardTitle>{t("ukrcaj")}</CardTitle>
            <ReFont>{String(currentTicket).padStart(4, "0")}</ReFont>
          </Card>
          <Card>
            <CardTitle>{t("prodano")}</CardTitle>
            <ReFont>{String(soldTickets).padStart(4, "0")}</ReFont>
          </Card>
          <Card className={online ? "online" : "offline"}>
            <Title className="bottom">{t("azurirano")}</Title>
            <Title className="bottom">{msState.data.updatedOn}</Title>
          </Card>
        </>
      )}
    </ScreenWrapper>
  );
}

export default Home;
