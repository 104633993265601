let token = { header: "" };
let messages = [];


const api = {
  getData: async () => {
    const result = { loading: false, data: null, error: true };
    const res = await fetch(`https://33de4a5e2ee2.smashing.hr/api/getData.php?u=${window._uuid_}`);
    if (!res.ok) return result;
    result.data = await res.json();
    result.error = false;
    return result;
  },
  getMessages: async () => {
    const result = { loading: false, data: null, error: true };
    const res = await fetch(`https://33de4a5e2ee2.smashing.hr/api/getMessages.php`);
    if (!res.ok) return result;
    result.data = await res.json();
    result.error = false;
    messages = result.data;
    return result;
  },
  checkStatus: async () => {
    const res = await fetch(
      "https://33de4a5e2ee2.smashing.hr/api/checkRemoteStatus.php"
    );
    if (!res.ok) return false;
    const data = await res.json();
    return data.online;
  },
  login: async (credentials) => {
    const res = await fetch("https://33de4a5e2ee2.smashing.hr/api//login.php", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify(credentials),
    });
    if (!res.ok) return false;
    const data = await res.json();
    return data.token;
  },
  setStatus: async (status) => {
    const res = await fetch("https://33de4a5e2ee2.smashing.hr/api/setStatus.php", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'Auth-Token':token.header
      },
      body: JSON.stringify(status),
    });
    return res.ok;
  },
};

export default api;
export { token, messages };
