import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import api, { token } from "./api";
import toast from "react-hot-toast";
import { messages } from "./api";

const ScreenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 768px;
  width: 100%;
  justify-content: start;
  margin: 32px auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #1b4788;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px;
  background: white;
  border: 1px solid #1b4788;
  border-radius: 3px;
  ::placeholder {
    color: black;
  }
`;

const Btn = styled.button`
  border: none;
  background-color: #1b4788;
  white-space: nowrap;
  color: hsla(150, 14%, 97%, 1);
  cursor: pointer;
  outline: none;
  font-size: 1rem;
  text-shadow: 0.1rem 0.1rem 0.5rem hsla(0, 0%, 0%, 0.5);
  letter-spacing: 0.1rem;
  border-radius: 0.5rem;
  user-select: none;
  padding: 0.5rem 2rem;
  margin: 12px auto;
  width: fit-content;
  text-decoration: none;

  ::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    background-color: blue;
  }

  &:active {
    background-color: ${({ buynow }) =>
      buynow ? "hsla(40, 72%, 35%, 1)" : "hsla(347, 49%, 26%, 1)"};
  }
`;

const Info = styled.div`
  margin: 16px 0px;
  text-align: justify;
  padding: 9px;
  border: 1px solid #1b4788;
  border-radius: 8px;
`;

export default function Admin() {

  const user = useRef();
  const pass = useRef();
  const [tokenHeader, setTokenHeader] = useState(token.header);
  const [msg, setMsg] = useState(messages[0].text_hr);
  const [msgIndex, setMsgIndex] = useState(-1);

  const handleLogin = async (ev) => {
    ev.preventDefault();
    let u = user.current.value;
    let p = pass.current.value;
    if (u === "" || p === "") return;
    const res = await api.login({ username: u, password: p });
    if (res === false) toast("Podaci za prijavu nisu ispravni.");
    token.header = res;
    setTokenHeader(res);
  };

  const handleChange = async (ev) => {

    const idx = Number(ev.target.value);
    const m = messages.filter(msg=>msg.id===ev.target.value);
    setMsg(m[0].text_hr);
    setMsgIndex(idx);
  };

  const handleSend = async (ev) => {
    ev.preventDefault();
    const res = await api.setStatus({ status: msgIndex });
    toast(res === true ? "Poruka je postavljena" : "Problem pri slanju poruke");
  };

  if (!tokenHeader)
    return (
      <ScreenWrapper>
        <Form autoComplete="off">
          <Input
            ref={user}
            name="user"
            autoComplete="new-password"
            type="text"
            placeholder="Korisnik"
          />
          <Input
            ref={pass}
            name="pass"
            autoComplete="new-password"
            type="password"
            placeholder="Šifra"
          />
          <Btn onClick={(ev) => handleLogin(ev)}>Prijava</Btn>
        </Form>
      </ScreenWrapper>
    );

  return (
    <ScreenWrapper>
      <label style={{ textAlign: "left", marginBottom: "8px" }}>Poruka</label>
      <select
        className="classic"
        style={{ width: "100%", fontSize: "14px" }}
        onChange={(ev) => handleChange(ev)}
      >
        <option defaultValue disabled>
          Izaberite poruku:
        </option>
        
        {messages.map(msg=>(<option value={msg.id}>{msg.header}</option>))}

        {/* <option value="0">Nesmetani rad</option>
        <option value="1">Očekuje se pogoršanje vremenskih uvjeta</option>
        <option value="2">
          Obustavljamo zbog pogoršanja vremenskih uvjeta
        </option>
        <option value="3">Usljed poboljšanja vremenskih uvjeta</option>
        <option value="4">
          Trenutno nam vremenski uvjeti ne omogućavaju organizaciju
        </option>
        <option value="5">Tijekom cijelog dana neće biti moguć posjet</option>
        <option value="6">Granični uvjeti rada</option>
        <option value="7">
          I nadalje nismo u mogućnosti osigurati prihvat
        </option>
        <option value="8">Obavijesti korisnicima RFID kartica</option>
        <option value="9">Glisiranje opomena</option>
        <option value="10">Sanitarni čvor ne radi</option> */}
      </select>
      <Btn onClick={(ev) => handleSend(ev)}>Postavi</Btn>
      <Info dangerouslySetInnerHTML={{ __html: msg }} />
    </ScreenWrapper>
  );
}


